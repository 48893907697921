$dark-bg-color: #282828;
$dark-bg-border-color: #464646;

$light-bg-color: #f3f3f3;
$light-bg-border-color: #828282;


$blue-primary: #0096f8;
$blue-secondary: #007ACC;
$blue-light: #CCEAFE;

$green-primary: #5DBF0C;
$green-secondary: #3F8406;
$green-light: #DFF2CE;

$orange-primary: #FEA424;
$orange-secondary: #AD6201;
$orange-light: #FFEDD3;

$red-primary: #EB4C49;
$red-secondary: #E6211E;
$red-light: #FBDBDB;

$purple-primary: #AF44CA;
$purple-secondary: #9531AF;
$purple-light: #EfDAF4;

$yellow-primary: #cbc82b;
$yellow-secondary: #ab9e1d;
$yellow-light: #f0bd19;