
// A Base rule is applied to an element using an element selector, a descendent selector, or a child selector, along with any pseudo-classes.
// It doesnt include any class or ID selectors. It is defining the default styling for how that element should look in all occurrences on the page.
// Base styles include setting heading sizes, default link styles, default font styles, and body backgrounds. There should be no need to use !important in a Base style.

@import 'settings/_settings.scss';

html {
  overflow-x: hidden; //zoomed elements creating scrollbars
}


textarea {
  min-height: 7rem;
  width: 100%;
}