@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?qw6lty');
  src:  url('../font/icomoon.eot?qw6lty#iefix') format('embedded-opentype'),
  url('../font/icomoon.ttf?qw6lty') format('truetype'),
  url('../font/icomoon.woff?qw6lty') format('woff'),
  url('../font/icomoon.svg?qw6lty#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
