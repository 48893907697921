%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', $body-font-family;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  @extend %icomoon;
}

%icon-enlarge:before,
.icon-enlarge:before {
  content: "\e98b";
}

%icon-shrink:before,
.icon-shrink:before {
  content: "\e98c";
}

%icon-attachment:before,
.icon-attachment:before {
  content: "\e9cd";
}

.icon-folder-clone:before {
  content: "\e900";
}

.icon-pencil:before {
  content: "\e905";
}

.icon-blog:before {
  content: "\e909";
}

.icon-copy:before {
  content: "\e92c";
}

.icon-folder-plus:before {
  content: "\e931";
}

.icon-folder-minus:before {
  content: "\e932";
}

.icon-history:before {
  content: "\e94d";
}

.icon-upload:before {
  content: "\e961";
}

.icon-enlarge:before {
  content: "\e98b";
}

.icon-shrink:before {
  content: "\e98c";
}

.icon-bin:before {
  content: "\e9ac";
}

.icon-menu2:before {
  content: "\e9be";
}

.icon-cloud-upload:before {
  content: "\e9c3";
}

.icon-upload2:before {
  content: "\e9c6";
}

.icon-impersonate:before, .icon-link:before {
  content: "\e9cb";
}

.icon-attachment:before {
  content: "\e9cd";
}

.icon-plus:before {
  content: "\ea0a";
}

.icon-play:before {
  content: "\ea1c";
}

.icon-first:before {
  content: "\ea21";
}

.icon-last:before {
  content: "\ea22";
}

.icon-previous:before {
  content: "\ea23";
}

.icon-next:before {
  content: "\ea24";
}

.icon-move-up:before {
  content: "\ea46";
}

.icon-checkbox-checked:before {
  content: "\ea52";
}

.icon-checkbox-unchecked:before {
  content: "\ea53";
}

.icon-mail2:before {
  content: "\ea84";
}

.icon-mail3:before {
  content: "\ea85";
}

.icon-home:before {
  content: "\e901";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-cat:before {
    content: "\e904";
}
