// There is a distinction between layouts dictating the major and minor components of a page. The minor components such as a callout, or login form,
// or a navigation item sit within the scope of major components such as a header or footer. I refer to the minor components as Modules and will dive
// into those in the next section. The major components are referred to as Layout styles.
// Layout styles can also be divided into major and minor styles based on reuse. Major layout styles such as header and footer are traditionally styled
// using ID selectors but take the time to think about the elements that are common across all components of the page and use class selectors where appropriate.

@import 'settings/_settings.scss';
@import 'utilities/_mixins.scss';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bgTile.png") #000 repeat center center;
}

.center-element {
  margin-left: auto;
  margin-right: auto;
}

header {
  background-color: $topbar-background;
  color: $white;

  > div {
    margin: 8px 0 10px 0;
  }

  .header-icon {
    width: 530px;
    height: 41px;
    background: url("../images/Header-Logo.png") no-repeat top left;
  }

  a {
    color: $white;
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

header:after {
  @include addColorfulBorder;
}


footer {
  background-color: #000;
  color: #fff;

  p {
    margin: 29px 0;
  }

  a,
  a:visited,
  a:hover {
    color: #fff;
  }

  a:hover {
    text-decoration: underline;
  }
}

footer:before {
  @include addColorfulBorder;
}
