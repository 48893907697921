.authenticated {
  main {
    background-color: $light-bg-color;

    .content-container {
      background-color: $white;
      border-left: 1px solid $light-bg-border-color;
      border-right: 1px solid $light-bg-border-color;
      padding: 0 0 2rem 0;

      > div {
        padding: 1rem 2rem 0 2rem;
      }

      .content-section {
        border-bottom: 1px solid $light-bg-border-color;
      }

      .breadcrumb, .submenu {
        button, .button-mock {
          margin-bottom: 1em;
        }
      }
    }
  }
}