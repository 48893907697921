.table-grid {
  thead th.is-clickable:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .align-top {
    vertical-align: top;
  }

  .button-link {
    text-decoration: underline;
  }

  &.shrink {
    width: auto;
  }
  &.align-center {
    margin-left: auto;
    margin-right: auto;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

$table-color-scale: 20%;
$table-padding: rem-calc(8 8);
$table-hover-scale: 5%;
$table-is-striped: false;
$table-stripe: even;
$table-head-font-color: $body-font-color;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: false;
$table-stack-breakpoint: medium;

@function table-border($background) {
  @return 1px solid smart-scale($background, $table-color-scale);
}

@function table-row-hover($background) {
  @return darken($background, $table-hover-scale);
}
@function table-row-stripe-hover($background) {
  @return darken($background, $table-hover-scale * 3);
}
@function table-striped-background($background) {
  @return smart-scale($background, $table-color-scale / 2);
}
@function table-head-background($background, $color-header-row-only) {
  @if($color-header-row-only) {
    @return $background;
  }
  @else {
    @return smart-scale($background, $table-color-scale / 2);
  }
}
@function table-head-row-hover($background, $color-header-row-only) {
  @if($color-header-row-only) {
    @return $background;
  }
  @else {
    @return smart-scale($background, $table-color-scale / 2);
  }
}
@function table-foot-background($background) {
  @return smart-scale($background, $table-color-scale / 2);
}
@function table-foot-row-hover($background) {
  @return smart-scale($background, $table-color-scale / 2);
}
@function get-background($background, $color-header-row-only) {
  @if($color-header-row-only) {
    @return $light-gray;
  }
  @else {
    @return $background;
  }
}


@mixin table-stripe($background, $stripe: $table-stripe) {
  tr {
    // If stripe is set to even, darken the even rows.
    @if $stripe == even {
      &:nth-child(even) {
        border-bottom: 0;
        background-color: table-striped-background($background);
      }
    }

      // If stripe is set to odd, darken the odd rows.
    @else if $stripe == odd {
      &:nth-child(odd) {
        background-color: table-striped-background($background);
      }
    }
  }
}

@mixin table-unstripe($background) {
  tr {
    border-bottom: 0;
    border-bottom: table-border($background);
    background-color: $background;
  }
}

@mixin table-children-styles($background, $color-header-row-only, $stripe: $table-stripe, $is-striped: $table-is-striped) {
  thead {
    border: table-border($background);
    background-color: $background;
  }

  tbody,
  tfoot {
    border: table-border(get-background($background, $color-header-row-only));
    background-color: get-background($background, $color-header-row-only);
  }

  // Caption
  caption {
    padding: $table-padding;
    font-weight: $global-weight-bold;
  }

  // Table head
  thead {
    background: table-head-background($background, $color-header-row-only);
    color: $table-head-font-color;
  }

  // Table foot
  tfoot {
    background: table-foot-background(get-background($background, $color-header-row-only));
    color: $table-foot-font-color;
  }

  // Table head and foot
  thead,
  tfoot {
    // Rows within head and foot
    tr {
      background: transparent;
    }

    // Cells within head and foot
    th,
    td {
      padding: $table-padding;
      font-weight: $global-weight-bold;
    }
  }

  // Table rows
  tbody {
    th,
    td {
      padding: $table-padding;
    }
  }

  // If tables are striped
  @if $is-striped == true {
    tbody {
      @include table-stripe(get-background($background, $color-header-row-only), $stripe);
    }

    &.unstriped {
      tbody {
        @include table-unstripe(get-background($background, $color-header-row-only));
        background-color: get-background($background, $color-header-row-only);
      }
    }
  }

    // If tables are not striped
  @else if $is-striped == false {
    tbody {
      @include table-unstripe(get-background($background, $color-header-row-only));
    }

    &.striped {
      tbody {
        @include table-stripe(get-background($background, $color-header-row-only), $stripe);
      }
    }
  }
}

/// Adds the general styles for tables.
/// @param {Keyword} $stripe [$table-stripe] - Uses keywords even, odd, or none to darken rows of the table. The default value is even.
@mixin color-table(
  $background,
  $color-header-row-only,
  $stripe: $table-stripe,
  $nest: false
) {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $global-margin;
  border-radius: $global-radius;

  @if $nest {
    @include table-children-styles($background, $color-header-row-only, $stripe);
  }
  @else {
    @at-root {
      @include table-children-styles($background, $color-header-row-only, $stripe);
    }
  }
}

/// Adds the ability to horizontally scroll the table when the content overflows horizontally.
@mixin color-table-scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

/// Slightly darkens the table rows on hover.
@mixin color-table-hover($background, $color-header-row-only) {
  thead tr {
    //Darkens the table header rows on hover.
    &:hover {
      background-color: table-head-row-hover($background, $color-header-row-only);
    }
  }

  tfoot tr {
    //Darkens the table footer rows on hover.
    &:hover {
      background-color: table-foot-row-hover(get-background($background, $color-header-row-only));
    }
  }

  tbody tr {
    //Darkens the non-striped table rows on hover.
    &:hover {
      background-color: table-row-hover(get-background($background, $color-header-row-only));
    }
  }

  @if $table-is-striped == true {
    // Darkens the even striped table rows.
    @if($table-stripe == even) {
      &:not(.unstriped) tr:nth-of-type(even):hover {
        background-color: table-row-stripe-hover(get-background($background, $color-header-row-only));
      }
    }

      // Darkens the odd striped table rows.
    @elseif($table-stripe == odd) {
      &:not(.unstriped) tr:nth-of-type(odd):hover {
        background-color: table-row-stripe-hover(get-background($background, $color-header-row-only));
      }
    }
  }

  @else if $table-is-striped == false {
    // Darkens the even striped table rows.
    @if($table-stripe == even) {
      &.striped tr:nth-of-type(even):hover {
        background-color: table-row-stripe-hover(get-background($background, $color-header-row-only));
      }
    }

      // Darkens the odd striped table rows.
    @elseif($table-stripe == odd) {
      &.striped tr:nth-of-type(odd):hover {
        background-color: table-row-stripe-hover(get-background($background, $color-header-row-only));
      }
    }
  }
}

/// Adds styles for a stacked table. Useful for small-screen layouts.
/// @param {Boolean} $header [$show-header-for-stacked] - Show the first th of header when stacked.
@mixin color-table-stack($header: $show-header-for-stacked) {
  @if $header {
    thead {
      th {
        display: block;
      }
    }
  }
  @else {
    thead {
      display: none;
    }
  }

  tfoot {
    display: none;
  }

  tr,
  th,
  td {
    display: block;
  }

  td {
    border-top: 0;
  }
}

@mixin color-table-grid($background, $color-header-row-only: true, $nest: true) {
  table {
    @include color-table($background, $color-header-row-only, $nest: $nest);
  }

  table.stack {
    @include breakpoint($table-stack-breakpoint down) {
      @include color-table-stack;
    }
  }

  table.scroll {
    @include color-table-scroll;
  }

  table.hover {
    @include color-table-hover($background, $color-header-row-only);
  }

  .table-scroll {
    overflow-x: auto;

    table {
      width: auto;
    }
  }
}
