.tile-blue {
  @include button-style($blue-primary, auto, $black);
}

.tile-green {
  @include button-style($green-primary, auto, $black);
}

.tile-orange {
  @include button-style($orange-primary, auto, $black);
}

.tile-red {
  @include button-style($red-primary, auto, $black);
}

.tile-purple {
  @include button-style($purple-primary, auto, $black);
}

.tile-yellow {
  @include button-style($yellow-primary, auto, $black);
}

.page-blue {
  @include buildTheme($blue-primary, $blue-secondary, $blue-light);
}
.page-green {
  @include buildTheme($green-primary, $green-secondary, $green-light);
}
.page-orange {
  @include buildTheme($orange-primary, $orange-secondary, $orange-light);
}
.page-red {
  @include buildTheme($red-primary, $red-secondary, $red-light);
}
.page-purple {
  @include buildTheme($purple-primary, $purple-secondary, $purple-light);
}
.page-yellow {
  @include buildTheme($yellow-primary, $yellow-secondary, $yellow-light);
}