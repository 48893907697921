@mixin spin($duration: 2s) {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin $duration infinite linear;
}

@mixin buttonLink($color: $primary-color) {
  border: 0;
  padding: 0;
  text-decoration:none;
  @include buttonJustText($color);

  &:disabled {
    background-color: transparent !important;
  }
}

@mixin buttonJustText($color: $primary-color) {
  background: none;
  border: none;

  &:enabled {
    color: $color;
  }
}

@mixin addColorfulBorder {
  content: "";
  width: 100%;
  height: 5px;
  display: block;
  background: #01bafc; /* Old browsers */
  background: -moz-linear-gradient(left, #01bafc 0%, #04bde5 4%, #06c2cb 8%, #17d35d 21%, #20d741 25%, #2fda28 29%, #48da17 36%, #62da10 41%, #b1da0e 55%, #d6da0e 62%, #eed00f 68%, #f1cd0e 70%, #f1c10e 73%, #f1a10e 79%, #f1730e 87%, #e3460e 94%, #dd2f0e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #01bafc 0%,#04bde5 4%,#06c2cb 8%,#17d35d 21%,#20d741 25%,#2fda28 29%,#48da17 36%,#62da10 41%,#b1da0e 55%,#d6da0e 62%,#eed00f 68%,#f1cd0e 70%,#f1c10e 73%,#f1a10e 79%,#f1730e 87%,#e3460e 94%,#dd2f0e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #01bafc 0%,#04bde5 4%,#06c2cb 8%,#17d35d 21%,#20d741 25%,#2fda28 29%,#48da17 36%,#62da10 41%,#b1da0e 55%,#d6da0e 62%,#eed00f 68%,#f1cd0e 70%,#f1c10e 73%,#f1a10e 79%,#f1730e 87%,#e3460e 94%,#dd2f0e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01bafc', endColorstr='#dd2f0e',GradientType=1 ); /* IE6-9 */
}

@mixin buildTheme($primary, $secondary, $light) {
  .button {
    @include button-style($primary,  scale-color($primary, $lightness: 25%), $black);
  }
  .button.secondary {
    @include button-style($secondary, scale-color($secondary, $lightness: 15%), $white);
  }

  .button.light {
    @include button-style($light, scale-color($light, $lightness: -15%), $black);
  }

  div.primary {
    background: $primary;
    color: $black;
  }
  div.secondary {
    background: $secondary;
    color: $white;
  }
  div.light {
    background: $light;
    color: $black;
  }

  .select-search-box__option--selected {
    background: $primary;
    color: $black;
    border-top-color: scale-color($primary, $lightness: -25%);
  }

  .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
    background: scale-color($primary, $lightness: -9%);
    color: $black;
    border-top-color: scale-color($primary, $lightness: -25%);
  }

  @include color-table-grid($light);
}

