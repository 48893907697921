@import '../utilities/_mixins.scss';

.loader {
  position: fixed;
  z-index: 99999;
  top: 48%;
  right: 48%;
  width: 100%;

  &:before {
    border: 5px solid $light-gray;
    border-top-color: $red;
    border-radius: 50%;
    content: '';
    display: block;
    overflow: hidden;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;

    @include spin(2s);
  }
}
