// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group prototype-rounded
////

/// Responsive breakpoints for rounded utility.
/// @type Boolean
$prototype-rounded-breakpoints: $global-prototype-breakpoints !default;

/// Default value for `prototype-border-radius`
/// @type Number
$prototype-border-radius: rem-calc(3) !default;

/// Rounded utility (all corners): Adds radius corners (all corners) to an element by default.
/// @param {Number} $radius [$prototype-border-radius] Border radius (all corners)
@mixin border-radius(
  $radius: $prototype-border-radius
) {
  border-radius: $radius;
}

/// Rounded square utility or rectangle utility (all corners): Rounds all corners to an element by default to make a pill shape.
@mixin border-rounded {
  border-radius: 5000px !important;
}

@mixin foundation-prototype-rounded {
  .rounded {
    @include border-rounded;
  }

  .radius {
    @include border-radius;
  }

  @if ($prototype-rounded-breakpoints) {
    // Loop through Responsive Breakpoints
    @each $size in $breakpoint-classes {
      @include breakpoint($size) {
        @if $size != $-zf-zero-breakpoint {
          .#{$size}-rounded {
            @include border-rounded;
          }
          .#{$size}-radius {
            @include border-radius;
          }
        }
      }
    }
  }
}
