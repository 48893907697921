.theme-print {

  section {
    margin: 2rem auto;
  }

  button {
    color: black !important;
    border-color: black !important;
  }

  .grid-x {
    margin: 1rem auto;
  }
}