#dashboard {
  padding: 3rem 0 2rem 0;

  .tile-container {
    padding: 0 2em 2em 2em;

    .tile {
      position: relative;
      width: 100%;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  .tile:before {
    content: "";
    display: block;
    padding-top: 75%;
  }

  a {
    color: $black;
    font-weight: $global-weight-bold;
  }
}