.import-errors {
  color: get-color(alert);

  div > ul {
    list-style: none;
    margin-left: 0;
    padding: 5px 15px;
    background-color: $light-gray;

    ul {
      margin-left: 2.5rem;
      margin-bottom: .5rem;
    }
  }
}