.enrollment-info {
  vertical-align: top;

  .button-mock.icons:before {
    padding-right: 20px;
  }


  .grid-x {
    margin-bottom: 1rem;
    .cell {
      padding: 3px;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
    }
    .cell:last-child {
      border-bottom: 1px solid $black;
    }
    .cell:first-child {
      border-top: 1px solid $black;
    }
  }

  .grid-x:last-child {
    margin-bottom: 0;
  }
}

