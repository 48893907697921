.button-mock, button {
  border: none;
  display: inline-block;

  &:not(:first-child) {
    margin-left: 1em;
  }
}

.button-link {
  @include buttonLink($black);
}

button {
  cursor: pointer;
}

.button {
  font-weight: bold;
}

.button-margin
{
  margin-#{$global-right}: 20px;
}