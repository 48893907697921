.text-danger {
  color: get-color(alert);
}


.input-bottom-alignment {
  .button-group, .button, .margin-bottom-0 select, .margin-bottom-0 input {
    margin-bottom: 0;
  }
}

%label-disabled {
  &.is-disabled {
    opacity: $button-opacity-disabled;
    background-color: $medium-gray;

    &:focus,
    &:hover {
      cursor: default;
      background-color: $medium-gray;
    }
  }
}

.fileUploadContainer {
  > span {
    line-height: 1.8;
    display: inline-block;
    margin-bottom: .5rem;
  }

  .mediaContainer {
    text-align: center;
    height: 60px;
    margin-bottom: .5rem;
  }
}

input[type="file"] {
  display: none;

  + label {
    font-weight: normal;

    @extend %label-disabled;

  }
}

label.button {
  @extend %label-disabled;
}