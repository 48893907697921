.public {
    main {
      background: url("../images/Logo.png") no-repeat center -125px;
      background-size: 800px auto;
      padding-top: 425px;

      label {
        color: $white;
      }

      .button-link {
        @include buttonLink($blue-primary);
      }

      .forgot-password {
        @include clearfix;

        .button-link {
          float: right;
          margin-bottom: -0.5rem;
        }
      }
    }

    &__container {
      background-color: $dark-bg-color;
      color: $white;
      border: 2px solid $dark-bg-border-color;
      margin-bottom: 1rem;
      text-align: center;
      padding: 1rem 0;
    }
}
